<template>
  <button
    class="toggle-button"
    :class="{ 'toggle-button--active': active, 'toggle-button--disabled': disabled, 'toggle-button--iframe': esCamaraIframe }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ToggleButton',
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('camera', ['esCamaraIframe']),
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.toggle-button {
  color: #242424;
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding-top: 0.625rem;
  padding-right: 1rem;
  padding-bottom: 0.625rem;
  padding-left: 1rem;
  font-size: 0.75rem;
  font-weight: 200;
  width: 100%;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
  }

  &--active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: white;
  }

  &--disabled {
    background-color: #e5e7eb;
    color: gray;
    cursor: not-allowed;
  }
}
</style>
