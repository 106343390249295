var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: { active: _vm.show },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "camera-selection__camera-counter" },
                    [
                      _vm._v(
                        _vm._s(_vm.project.cameras.length) +
                          " " +
                          _vm._s(_vm.$t("lang.camera.msg"))
                      )
                    ]
                  ),
                  _vm.map
                    ? _c(
                        "router-link",
                        {
                          staticClass: "camera-selection__map-button",
                          attrs: {
                            to: {
                              name: "project-home",
                              params: { id: _vm.project.id, view: "map" }
                            },
                            tabindex: "-1"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lang.see_map.msg")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "camera-selection__cameras" },
        _vm._l(_vm.project.cameras, function(camera) {
          return _c(
            "div",
            {
              key: camera.id,
              staticClass: "camera-selection__camera",
              class: {
                "camera-selection__camera--selected":
                  _vm.currentCamera.id === camera.id
              },
              on: {
                click: function($event) {
                  return _vm.handleSelectCamera(camera)
                }
              }
            },
            [
              _c("img", {
                staticClass: "camera-selection__camera-photo",
                attrs: { src: _vm.coverImageUrl(camera), alt: camera.name }
              }),
              _c(
                "svg",
                {
                  staticClass: "camera-selection__camera-check",
                  attrs: { fill: "currentColor", viewBox: "0 0 20 20" }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d:
                        "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                      "clip-rule": "evenodd"
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "camera-selection__camera-info" }, [
                _c("span", { staticClass: "camera-selection__camera-name" }, [
                  _vm._v(" " + _vm._s(camera.name) + " ")
                ])
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }