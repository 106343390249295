<template>
  <div class="camera-selection-button" :class="{ 'camera-selection-button--active': active }">
    <ToggleButton class="w-100" :active="active" @click="$emit('click')" :disabled="disabled">
      {{ camera.name }}
      <svg
        class="camera-selection-button__chevron-active"
        height="18"
        width="18"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <svg
        class="camera-selection-button__chevron-inactive"
        height="18"
        width="18"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </ToggleButton>
    <div class="small text-center" :class="{ 'small--iframe': esCamaraIframe }">{{$t('lang.selected_camera.msg')}}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToggleButton from '@/components/ToggleButton';

export default {
  name: 'CameraSelectorButton',
  components: {
    ToggleButton,
  },
  props: {
    active: { type: Boolean, default: false },
    camera: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('camera', ['esCamaraIframe']),
  },
};
</script>

<style lang="scss" scoped>
.camera-selection-button {
  $self: &;
  // min-width: 10.25rem

  &--active {
    #{ $self }__chevron-inactive {
      display: none;
    }
    #{ $self }__chevron-active {
      display: inline;
    }
  }

  &__chevron-active {
    display: none;
  }
}
</style>
