<template>
  <div class="drawer" :class="{ 'drawer--active': active }">
    <div class="drawer__wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDrawer',
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.drawer {
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  bottom: -100%;

  @include media-breakpoint-up(md) {
    bottom: -60%;
    height: 60%;
  }

  &--active {
    bottom: 0;
  }

  &::before {
    content: '';
    background-color: #232323;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
    width: 100%;
  }
}
</style>
