var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tl-modal", class: { "tl-modal--closed": !_vm.open } },
    [
      _c(
        "div",
        _vm._b(
          { staticClass: "tl-modal__container" },
          "div",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "div",
            _vm._b(
              { staticClass: "tl-modal__wrapper" },
              "div",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "button",
                {
                  staticClass: "tl-modal__close",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _vm._t("closeiconmodal", [
                    _c("i", { staticClass: "tmlps-icn-delete-search h3" })
                  ])
                ],
                2
              ),
              _vm._t("titlemodal", [
                _c("h3", { staticClass: "tl-modal__title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "div",
                { staticClass: "tl-modal__body" },
                [_vm._t("default")],
                2
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }