<template>
  <div class="tl-modal" :class="{ 'tl-modal--closed': !open }">
    <div class="tl-modal__container" v-bind="$attrs">
      <div class="tl-modal__wrapper" v-bind="$attrs">
        <button class="tl-modal__close" @click="$emit('close')">
          <slot name="closeiconmodal">
            <i class="tmlps-icn-delete-search h3"></i>
          </slot>
        </button>

        <slot name="titlemodal">
          <h3 class="tl-modal__title">{{ title }}</h3>
        </slot>

        <div class="tl-modal__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  inheritAttrs: false,
  props: {
    open: { type: Boolean, default: false },
    title: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.tl-modal {
  $self: &;
  // visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 1rem;

  &::before {
    content: '';
    background-color: #2d2d2d;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    // transition-property: opacity;
    // transition-duration: 0.3s;
    // transition-timing-function: ease;
  }

  &--closed {
    // visibility: hidden
    display: none;
    &::before {
      opacity: 0;
    }
  }

  &__close {
    background-color: transparent;
    border: none;
    color: #484848;
    padding: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__container {
    width: 100%;
    z-index: 10;
  }

  &__wrapper {
    max-width: 34.125rem;
    max-height: 100%;
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.25rem 1.25rem 2.5rem;
    position: relative;
    margin: 0 auto;
  }

  &__title {
    color: #242424;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #c1c1c1;
  }

  &__body {
    margin-top: 1.25rem;
    overflow-y: auto;
  }
}
</style>
