<template>
  <Drawer :active="show" @close="$emit('close')">
    <template v-slot:header>
      <div>
        <span class="camera-selection__camera-counter">{{ project.cameras.length }} {{$t('lang.camera.msg')}}</span>
        <router-link
          :to="{ name: 'project-home', params: { id: project.id, view: 'map' } }"
          tabindex="-1"
          class="camera-selection__map-button"
          v-if="map"
        >
        {{$t('lang.see_map.msg')}}
        </router-link>
      </div>
    </template>

    <div class="camera-selection__cameras">
      <div
        class="camera-selection__camera"
        :class="{
          'camera-selection__camera--selected': currentCamera.id === camera.id,
        }"
        @click="handleSelectCamera(camera)"
        v-for="camera in project.cameras"
        :key="camera.id"
      >
        <img
          class="camera-selection__camera-photo"
          :src="coverImageUrl(camera)"
          :alt="camera.name"
        />
        <svg class="camera-selection__camera-check" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <div class="camera-selection__camera-info">
          <span class="camera-selection__camera-name">
            {{ camera.name }}
          </span>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer';

export default {
  name: 'CameraSelectorDrawer',
  components: {
    Drawer,
  },
  props: {
    project: { type: Object, required: true },
    currentCamera: { type: Object, required: true },
    show: { type: Boolean, default: false },
    map: { type: Boolean, default: true },
    closeOnSelectCamera: { type: Boolean, default: true },
  },
  methods: {
    coverImageUrl(camera) {
      if (camera.type === 'third-party') {
        return camera.cover_image_url ?? '/img/placeholder-ip-camera-2.jpg';
      }
      return camera?.last_photo?.thumbnail_url ?? '/img/photo-placeholder.jpg';
    },
    handleSelectCamera(camera) {
      this.$emit('select', camera);

      if (this.closeOnSelectCamera) {
        this.$emit('close', camera);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.camera-selection {
  &__camera-counter {
    font-weight: bold;
  }
  &__map-button {
    font-size: 0.875rem;
    background-color: white;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding-top: 0.25rem;
    padding-right: 1.5rem;
    padding-bottom: 0.25rem;
    padding-left: 1.5rem;
    margin-left: 1.5rem;
    text-decoration: none;
    color: inherit;
    font-weight: 300;
  }
  &__cameras {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(md) {
      padding: 0 2.5rem 2.5rem;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  &__camera {
    $self: &;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;

      img {
        width: 310px;
        height: 230px;
        object-fit: cover;
      }
    }

    @include media-breakpoint-up(lg) {
      img {
        width: 215px;
        height: 142px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--selected {
      #{ $self }-check {
        display: block;
      }
    }
  }
  &__camera:first-child {
    margin-left: 0;
  }
  &__camera-photo {
    width: 100%;
  }
  &__camera-check {
    display: none;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    fill: rgba(78, 207, 59, 0.8);
    color: white;
    background-color: white;
    border-radius: 100%;
  }
  &__camera-info {
    padding-top: 1rem;
  }
  &__camera-name {
    font-size: 0.875rem;
  }
}
</style>
