var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "toggle-button",
      class: {
        "toggle-button--active": _vm.active,
        "toggle-button--disabled": _vm.disabled,
        "toggle-button--iframe": _vm.esCamaraIframe
      },
      attrs: { disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }