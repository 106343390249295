var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDrawer", { attrs: { active: _vm.active } }, [
    _c("div", { staticClass: "drawer__container" }, [
      _c(
        "div",
        { staticClass: "drawer__header", attrs: { name: "header" } },
        [
          _vm._t("header"),
          _c(
            "button",
            {
              staticClass: "drawer__close",
              attrs: { tabindex: "-1" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("i", { staticClass: "tmlps-icn-delete-search h3" })]
          )
        ],
        2
      ),
      _c("div", { staticClass: "drawer__content" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }