var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-selector",
      class: { "project-selector--iframe": _vm.esCamaraIframe }
    },
    [
      !_vm.esPublico
        ? _c(
            "router-link",
            {
              staticClass: "text-decoration-none",
              attrs: { to: { name: "project-list" } }
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-selector__logo",
                  class: {
                    "project-selector__logo--iframe": _vm.esCamaraIframe
                  }
                },
                [
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: _vm.projectLogoUrl, alt: _vm.projectName }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "project-selector__label",
                  class: {
                    "project-selector__label--iframe": _vm.esCamaraIframe
                  }
                },
                [_vm._v(_vm._s(_vm.$t("lang.return.msg")))]
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "project-selector__logo",
              class: { "project-selector__logo--iframe": _vm.esCamaraIframe }
            },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.projectLogoUrl, alt: _vm.projectName }
              })
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }